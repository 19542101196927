//export const BASE_URL = "http://localhost:1337/api";
//export const BASE_URL_IMG = "http://localhost:1337/";
export const BASE_URL = "https://bedoin.com.ua/api";
export const BASE_URL_IMG = "https://bedoin.com.ua/";

// export const BASE_URL = "https://1603-46-98-139-214.ngrok-free.app/api";
// export const BASE_URL_IMG = "https://1603-46-98-139-214.ngrok-free.app/";
export const SLIDER_BUTTON_TYPES = {
    NEXT: 'NEXT',
    PREV: 'PREV'
};

