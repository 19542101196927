import React from 'react';
import './spiner.scss'

const Spiner = () => {
    return (
        <div className='spiner'>
            <svg width="146" height="172" viewBox="0 0 146 172" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M44.0475 86.4507C44.0475 82.1207 43.9175 77.7907 44.0875 73.4707C44.2475 69.4707 46.9275 67.8407 50.4275 69.8807C57.4575 73.9707 64.4175 78.2107 71.1575 82.7607C74.2375 84.8407 74.1875 88.1607 71.1175 90.2307C64.3675 94.7707 57.4075 99.0107 50.3775 103.101C46.9075 105.121 44.2275 103.441 44.0775 99.4207C43.9275 95.1107 44.0475 90.7807 44.0475 86.4507Z" fill="black"/>
                <path d="M0.0735838 85.6218C0.0735838 65.9718 0.223582 46.3218 0.0135824 26.6718C-0.0964176 15.9518 4.04358 8.02184 13.5936 2.99184C22.9736 -1.94816 31.8536 -0.418158 40.4536 5.00184C71.7436 24.7218 103.054 44.4118 134.314 64.1818C148.924 73.4218 149.464 97.5218 133.884 107.202C106.754 124.052 79.7936 141.172 52.7636 158.172C47.2436 161.642 41.9136 165.472 36.1436 168.462C19.5536 177.062 -0.306416 164.192 0.00358409 146.062C0.333584 125.922 0.0735838 105.772 0.0735838 85.6218ZM118.494 85.6318C87.4836 66.0218 57.0236 46.7618 26.4636 27.4318C26.4636 66.5218 26.4636 104.992 26.4636 143.822C57.1736 124.392 87.4536 105.252 118.494 85.6318Z" fill="black"/>
                <path d="M33.0736 85.7918C33.0736 74.6418 33.0536 63.4818 33.0836 52.3318C33.1036 45.1018 36.5836 42.7918 42.7836 46.5218C54.7936 53.7418 66.5136 61.4218 78.3536 68.9118C84.2736 72.6518 90.2436 76.3218 96.1036 80.1518C101.204 83.4718 101.334 87.8218 96.3036 91.0418C79.0136 102.112 61.6636 113.082 44.2936 124.022C42.8036 124.962 41.0736 125.732 39.3636 126.052C35.1336 126.832 33.1136 125.092 33.0936 120.762C33.0336 109.102 33.0736 97.4418 33.0736 85.7918ZM90.5536 85.6018C73.4236 74.8018 57.0836 64.5118 40.3436 53.9618C40.3436 75.3918 40.3436 95.9018 40.3436 117.342C57.2836 106.632 73.6236 96.3018 90.5536 85.6018Z" fill="black"/>
            </svg>
        </div>
    );
};

export default Spiner;